<template>
  <div
    v-if="visible"
    class="base-spinner fa-3x"
  >
    <i class="fas fa-circle-notch fa-spin" />
  </div>
</template>

<script>

  export default {
    data() {
      return {
        visible: true
      }
    },
    created() {
      this.$root.$on('Spinner::show', () => { this.visible = true })
      this.$root.$on('Spinner::hide', () => { this.visible = false })
    }
  }

</script>

<style lang="scss">

</style>