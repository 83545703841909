<template>
  <div id="app">
    <router-view />
    <base-spinner />
  </div>
</template>

<script>
  import BaseSpinner from "@/components/global/BaseSpinner"

  export default {
    name: "App",
    components: {
      BaseSpinner
    },
    mounted() {
      setTimeout(() => { this.$root.$emit('Spinner::hide') }, 2000)
    },
    methods: {
    }
  }

</script>

<style lang="scss" scoped>

.base-spinner {
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.8);
  color: #28a745 !important;
  z-index: 999999;
}

</style>
