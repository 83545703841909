import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import beforeEach from './beforeEach'

Vue.use(Router)

const router = new Router({
    //mode: 'history',
    routes: routes
})
router.beforeEach(beforeEach)


// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
//
// router.beforeEach((to,from,next) => {
//   next()
// })
//

export default router
