export default [
    {
        name: 'clientes',
        path: '/clientes',
        component: () => import('./pages/Clientes.vue')
    },
    {
        name: 'clientes-lixeira',
        path: '/clientes-lixeira',
        component: () => import('./pages/ClientesLixeira.vue')
    }
]