export default [
    {
        name: 'alunos',
        path: '/alunos',
        component: () => import('./pages/Alunos.vue')
    },
    {
        name: 'alunosTrash',
        path: '/alunosTrash',
        component: () => import('./pages/AlunosTrash.vue')
    }
]