import Vue from 'vue';
import store from './../store'
import axios from "axios";

// Global http with axios
const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`
});

api.defaults.headers.common['Accept'] = 'application/json';
api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`;

Vue.prototype.$api = api;