import { routes as auth } from '../modules/auth'
import { routes as dashboard } from '../modules/dashboard'
import { routes as provas } from '../modules/provas'
import { routes as usuarios } from '../modules/usuarios'
import { routes as agenda } from '../modules/agenda'
import { routes as perfil } from '../modules/perfil'
import { routes as questoes } from '../modules/questoes'
import { routes as resultados } from '../modules/resultados'
import { routes as contato } from '../modules/contato'
import { routes as faq} from '../modules/faq'
import { routes as clientes } from '../modules/clientes'
import { routes as instituicoes } from '../modules/instituicoes'
import { routes as turmas } from '../modules/turmas'
import { routes as anos } from '../modules/ano'
import { routes as professores } from '../modules/professores'
import { routes as alunos } from '../modules/alunos'

export default [
    ...auth,
    ...dashboard,
    ...provas,
    ...usuarios,
    ...agenda,
    ...perfil,
    ...questoes,
    ...resultados,
    ...contato,
    ...faq,
    ...clientes,
    ...instituicoes,
    ...turmas,
    ...anos,
    ...professores,
    ...alunos
]