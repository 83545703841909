export default [
    {
        name: 'resultados',
        path: '/resultados',
        component: () => import('./pages/ResultadoProvaLista.vue')
    },
    {
        name: 'resultados-alunos',
        path: '/resultados-alunos/:idprova',
        component: () => import('./pages/ResultadoProvaAlunos.vue')
    },
    {
        name: 'resultados-detalhe-prova-aluno',
        path: '/resultados-detalhe-prova-aluno/:idprova/aluno/:idaluno',
        component: () => import('./pages/ResultadoDetalheProvaAluno.vue')
    },
    {
        name: 'resultados-detalhe-prova-geral',
        path: '/resultados-detalhe-prova-geral/:idprova',
        component: () => import('./pages/ResultadoDetalheProvaGeral.vue')
    },
    {
        name: 'resultados-turma',
        path: '/resultados-turma',
        component: () => import('./pages/ResultadoTurmaLista.vue')
    },
    {
        name: 'resultados-turma-prova',
        path: '/resultados-turma-prova/:idturma/ano/:idano/disciplina/:iddisciplina',
        component: () => import('./pages/ResultadoTurmaProva.vue')
    },
    {
        name: 'resultados-aluno-lista',
        path: '/resultados-aluno-lista',
        component: () => import('./pages/ResultadoAlunoLista.vue')
    },
    {
        name: 'resultados-aluno-prova',
        path: '/resultados-aluno-prova/:idaluno',
        component: () => import('./pages/ResultadoAlunoProva.vue')
    }
]