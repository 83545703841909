import {constantes} from "@/constants";
import axios from "axios";

export const checkUserHaveRole = (roles) => {

    // if(localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES).includes(constantes.ROLE_SUPER_ADMIN)){
    //     return true;
    // } else {
    //     var result = false
    //     roles.forEach((role) => {
    //         result = localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES).includes(role);
    //     });
    //     return result;
    // }

    var permitido = false;
    if(localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES)){
        roles.forEach((role) => {
            if(localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES).includes(role)) {
                permitido = true;
            }
        });
    }
    return permitido;

}

export const externalRequest = (url) => {

    // Global http with axios
    const api = axios.create({
    baseURL: url
    });

    api.defaults.headers.common['Accept'] = 'application/json';
    api.defaults.headers.common['Content-Type'] = 'application/json';

    return api;
}