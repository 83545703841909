import {checkUserHaveRole} from "@/tools";
import {constantes} from "@/constants";

export default [
    {
        name: 'provas',
        path: '/provas',
        component: () => import('./pages/Provas.vue')
    },
    {
        name: 'provas_com_filtro',
        path: '/provas/:filter',
        component: () => import('./pages/Provas.vue')
    },
    {
        name: 'provas_create',
        path: '/criar-prova',
        component: () => import('./pages/ProvasCreate.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([constantes.ROLE_PROFESSOR])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    },
    {
        name: 'provas_edit',
        path: '/editar-prova/:id',
        component: () => import('./pages/ProvasEdit.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([constantes.ROLE_PROFESSOR])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    },
    {
        name: 'provas_aluno_corrigir',
        path: '/prova-aluno-corrigir/:prova/:aluno',
        component: () => import('./pages/ProvasCorrigir.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([constantes.ROLE_PROFESSOR])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    },
    {
        name: 'provas_para_correcao',
        path: '/lista-prova-correcao/:id',
        component: () => import('./pages/ProvasListarCorrecao.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([constantes.ROLE_PROFESSOR])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    }
    
]