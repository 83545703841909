import {checkUserHaveRole} from "@/tools";
import {constantes} from "@/constants";

export default [
    {
        name: 'questoes',
        path: '/banco-de-questoes',
        component: () => import('./pages/Questoes.vue')
    },
    {
        name: 'questoes_trash',
        path: '/banco-de-questoes-trash',
        component: () => import('./pages/QuestoesLixo.vue')
    },
    {
        name: 'questao_edit',
        path: '/questao-edit/:id',
        component: () => import('./pages/QuestaoEdit.vue')
    },
    {
        name: 'questao_create',
        path: '/questao-create',
        component: () => import('./pages/QuestaoCreate.vue')
    },
    {
        name: 'componente_curricular',
        path: '/componente-curricular',
        component: () => import('./pages/Disciplinas.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([constantes.ROLE_SUPER_ADMIN, constantes.ROLE_INSTITUICAO, constantes.ROLE_INSTITUICAO_ADMIN])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    },
    {
        name: 'componenteCurricularLixo',
        path: '/componente-curricular-lixo',
        component: () => import('./pages/DisciplinasLixo.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([constantes.ROLE_SUPER_ADMIN, constantes.ROLE_INSTITUICAO, constantes.ROLE_INSTITUICAO_ADMIN])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    },
    {
        name: 'habilidades',
        path: '/habilidades',
        component: () => import('./pages/Assuntos.vue')
    }
]