import {checkUserHaveRole} from "@/tools";
import {constantes} from "@/constants";

export default [
    {
        name: 'instituicoes',
        path: '/instituicoes',
        component: () => import('./pages/Instituicoes.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([
                constantes.ROLE_SUPER_ADMIN,
                constantes.ROLE_CLIENTE
            ])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    },
    {
        name: 'instituicoes-tash',
        path: '/instituicoes-tash',
        component: () => import('./pages/InstituicoesLixeira.vue'),
        beforeEnter(to, from, next) {
            if(checkUserHaveRole([
                constantes.ROLE_SUPER_ADMIN,
                constantes.ROLE_CLIENTE
            ])) {
                next();
            } else {
                console.log('permission denied');
            }
        }
    }
]