import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueTheMask from 'vue-the-mask'
import './plugins'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import CKEditor from '@ckeditor/ckeditor5-vue2';

import('./assets/scss/' + process.env.VUE_APP_EMPRESA + '.scss')

Vue.config.productionTip = false
Vue.use( CKEditor );

window._Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('strpad', function (value) {
  if(value>=1){
    return '0' + value
  } else if(value==0) {
    return '0'
  } else {
    return value
  }
});



Vue.use(BootstrapVue)

Vue.use(VueTheMask)
