export default [
    {
        name: 'professores',
        path: '/professores',
        component: () => import('./pages/Professores.vue')
    },
    {
        name: 'professoresTrash',
        path: '/professoresTrash',
        component: () => import('./pages/ProfessoresLixeira.vue')
    }
]