export default [
    {
        name: 'anos',
        path: '/anos',
        component: () => import('./pages/Anos.vue')
    },
    {
        name: 'anosLixo',
        path: '/anosLixo',
        component: () => import('./pages/AnosLixo.vue')
    }
]